import Link from '@swe/shared/providers/router/link';
import { Breadcrumb, Breadcrumbs } from '@swe/shared/ui-kit/components/breadcrumbs';
import { capitalize } from '@swe/shared/utils/string';

import { ReactNode, useMemo } from 'react';

import { useStoreConfig } from 'common/providers/config';

import { useRouterAsPath } from 'common/router';
import { getBreadcrumbTitle, Routes, ShopRoute } from 'common/router/constants';

const generatePathParts = (pathStr: string) => {
  const pathWithoutQuery = pathStr.split('?')[0];
  return pathWithoutQuery.split('/').filter((v) => v.length > 0);
};

type BreadcrumbObject = {
  href?: ShopRoute;
  text: ReactNode;
};

type Replacement = {
  breadcrumb: BreadcrumbObject;
  position: 'first' | 'last' | number;
};

type PathBasedBreadcrumbsProps = {
  breadcrumbs?: BreadcrumbObject[];
  getReplacement?: () => Replacement[];
};

const getPosition = (list: any[], pos: 'first' | 'last') => {
  switch (pos) {
    case 'first':
      return 0;
    case 'last':
      return list.length - 1;
  }
};

const prepareSubpath = (subpath: string) => {
  return subpath.split('-').map(capitalize).join(' ');
};

const PathBasedBreadcrumbs = ({ breadcrumbs: _outerBreadcrumbs, getReplacement }: PathBasedBreadcrumbsProps) => {
  const asPath = useRouterAsPath();
  const { isTruncatedShop } = useStoreConfig();

  const breadcrumbs = useMemo<BreadcrumbObject[]>(() => {
    let breadcrumbList: BreadcrumbObject[] = isTruncatedShop ? [] : [{ href: Routes.Home, text: 'Home' }];
    if (_outerBreadcrumbs) {
      breadcrumbList = [...breadcrumbList, ..._outerBreadcrumbs];
    } else {
      const asPathNestedRoutes = generatePathParts(asPath);
      breadcrumbList = [
        ...breadcrumbList,
        ...generatePathParts(asPath).map((subpath, idx) => {
          const href = `/${asPathNestedRoutes.slice(0, idx + 1).join('/')}`;
          return {
            href,
            text: getBreadcrumbTitle(href) ?? prepareSubpath(subpath),
          } as BreadcrumbObject;
        }),
      ];
    }

    const replacements = getReplacement?.();
    if (replacements) {
      replacements.forEach((replacement) => {
        const position =
          typeof replacement.position === 'string'
            ? getPosition(breadcrumbList, replacement.position)
            : replacement.position;
        breadcrumbList[position] = replacement.breadcrumb;
      });
    }

    return breadcrumbList;
  }, [isTruncatedShop, _outerBreadcrumbs, getReplacement, asPath]);

  return (
    <Breadcrumbs>
      {breadcrumbs.map((breadcrumb, idx) => (
        <Breadcrumb
          key={(typeof breadcrumb.href === 'object' ? breadcrumb.href.pathname : breadcrumb.href) ?? idx}
          href={breadcrumb.href}
          renderLink={Link}
        >
          {breadcrumb.text}
        </Breadcrumb>
      ))}
    </Breadcrumbs>
  );
};

export type { PathBasedBreadcrumbsProps };
export { PathBasedBreadcrumbs };
