import { useContext, useState, useEffect, useCallback, createContext, useRef } from 'react';

import { IS_DEV } from '@swe/shared/tools/env';
import { setup, IntlCore, Language, Messages } from '@swe/shared/tools/intl/core/i18n';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

export type IntlContextType = {
  intl: IntlCore;
};

export const IntlContext = createContext<IntlContextType | null>(null);

const useIntl = () => {
  const context = useContext(IntlContext);

  if (IS_DEV) {
    if (context == null) {
      throw new Error('useLingui hook was used without I18nProvider.');
    }
  }

  return context as IntlContextType;
};

const IntlProvider = ({
  language,
  messages,
  children,
}: ComponentHasChildren & {
  language: Language;
  messages: Messages;
}) => {
  const [intl] = useState<IntlCore>(() => setup());

  if (language !== intl.language || intl.isMessagesEmpty) {
    intl.loadAndActivate(language, messages);
  }
  const latestKnownLocale = useRef<string | undefined>(language);
  const makeContext = useCallback(
    () => ({
      intl,
    }),
    [intl],
  );

  const [context, setContext] = useState<IntlContextType>(makeContext());

  useEffect(() => {
    const updateContext = () => {
      latestKnownLocale.current = intl.language;
      setContext(makeContext());
    };
    const unsubscribe = intl.on('change', updateContext);
    return unsubscribe;
  }, [intl, makeContext]);

  if (!latestKnownLocale.current) {
    if (IS_DEV)
      console.info(
        'I18nProvider rendered `null`. A call to `i18n.activate` needs to happen in order for translations to be activated and for the I18nProvider to render.' +
          'This is not an error but an informational message logged only in development.',
      );

    return null;
  }

  return <IntlContext.Provider value={context}>{children}</IntlContext.Provider>;
};

export { IntlProvider, useIntl };
