import { Product, ProductSaleType } from 'entities/product/product';

const getProductImageAlt = (product: Product) => {
  const { brand, name } = product;
  return `${name}${brand?.name ? ` ${brand.name}` : ''}`;
};

const getProductImageTitle = getProductImageAlt;

const getLogoAlt = (title: string) => {
  return `Cannabis store ${title}`;
};

const getCategoryAlt = (categoryName: string) => {
  return `Buy ${categoryName} Cannabis`;
};

const getBannerAlt = (name: string) => {
  return `Cannabis Promo, Cannabis Sales, Cannabis Discounts, Cannabis on Sale, ${name}`;
};

const getSeoSaleTypes = (saleTypes: ProductSaleType[]) =>
  saleTypes.map((type) => (type === 'Recreational' ? 'Personal' : type));

const getSaleTypeMsg = (saleTypes: ProductSaleType[]) =>
  `for ${getSeoSaleTypes(saleTypes).join(' and ').toLowerCase()} use`;

const seoBuilder = ({ after }: { after?: () => void } = {}) => {
  const separator = '|';
  const title: (string | undefined)[] = [];
  const description: (string | undefined)[] = [];
  const keywords: (string | undefined)[] = [];

  const filter = (pats: (string | undefined)[]) => pats.filter((part) => !!part) as string[];

  const fn = (obj: (string | undefined)[]) => ({
    add(part: string | undefined | (string | undefined)[]) {
      obj.push(...(Array.isArray(part) ? part : [part]));
      return this;
    },
  });

  return {
    title: fn(title),
    description: fn(description),
    keywords: fn(keywords),
    toProps: () => {
      after?.();
      return {
        title: filter(title).join(` ${separator} `),
        description: filter(description).join(` ${separator} `),
        keywords: filter(keywords),
      };
    },
  };
};

export {
  seoBuilder,
  getProductImageAlt,
  getProductImageTitle,
  getLogoAlt,
  getCategoryAlt,
  getBannerAlt,
  getSeoSaleTypes,
  getSaleTypeMsg,
};
