import { ReactNode } from 'react';

import { Popover, UsePopoverProps } from '@swe/shared/ui-kit/components/popover';
import { useTheme } from '@swe/shared/ui-kit/theme/provider';
import { ComponentHasChildren, ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type TooltipProps = ComponentHasClassName &
  ComponentHasChildren & {
    content: ReactNode | ReactNode[];
    trigger?: UsePopoverProps['trigger'];
    contentMaxWidth?: number;
  };

const Tooltip = ({
  className,
  content: _content,
  children,
  contentMaxWidth = 290,
  trigger = 'hover',
}: TooltipProps) => {
  const { tooltip } = useTheme();
  const content = Array.isArray(_content) ? _content : [_content];
  const multilineContent = content.map((t) => <div>{t}</div>);

  return (
    <Popover
      className={className}
      content={
        <div
          style={{ maxWidth: contentMaxWidth }}
          className={styles.content}
        >
          {multilineContent}
        </div>
      }
      trigger={trigger}
      placement="top"
      offset="xxxxs"
      arrow={{
        width: 12,
        height: 8,
        strokeWidth: 0,
        fill: tooltip.backgroundColor,
      }}
    >
      {children}
    </Popover>
  );
};

export { Tooltip };
export type { TooltipProps };
export default Tooltip;
