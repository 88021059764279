import { useBreakpoint } from '@swe/shared/tools/media';
import { Carousel } from '@swe/shared/ui-kit/components/carousel-v2';
import { CarouselItemT } from '@swe/shared/ui-kit/components/carousel-v2/types';

import { withSkeleton } from '@swe/shared/ui-kit/components/skeleton';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cx from 'clsx';

import { useMemo } from 'react';

import styles from './styles.module.scss';

import { ProductImageCarouselSkeleton } from 'domains/product/components/product-details/components/image/skeleton';
import { getProductImageAlt, getProductImageTitle } from 'entities/common/seo';
import { Product, ProductVariant } from 'entities/product/product';

type ProductImageCarouselProps = ComponentHasClassName & {
  product?: Product;
  variant?: ProductVariant;
};

const ProductImageCarousel = ({ className, product, variant }: ProductImageCarouselProps) => {
  const { mobile } = useBreakpoint();

  const images = useMemo(() => {
    if (variant?.images && variant.images.length > 0) {
      return variant.images;
    }

    if (product?.images && product.images.length > 0) {
      return product.images;
    }

    return [];
  }, [product?.images, variant?.images]);

  const carouselItems = useMemo<CarouselItemT[]>(
    () =>
      images.length === 0
        ? [
            {
              type: 'image',
              alt: product ? getProductImageAlt(product) : '',
              title: product ? getProductImageTitle(product) : '',
              src: undefined,
              ratio: 1,
              width: 412,
              lazy: false,
              loading: !(product && variant),
              fit: 'contain',
              emptyImage: 'placeholder',
            },
          ]
        : images.map((imageUrl) => ({
            type: 'image',
            alt: product ? getProductImageAlt(product) : '',
            title: product ? getProductImageTitle(product) : '',
            src: imageUrl,
            ratio: 1,
            width: 412,
            lazy: false,
            fit: 'contain',
            emptyImage: 'placeholder',
          })),
    [images, product, variant],
  );

  return (
    <div className={cx(styles.root, className)}>
      <Carousel
        slideClassName={styles.carouselItem}
        thumbClassName={styles.thumbItem}
        items={carouselItems}
        stepper={mobile ? 'dot' : false}
        extendToView={mobile}
        gap={mobile ? 'equal' : 'md'}
        thumbs={!mobile}
        controls={!mobile}
        infinite
      />
    </div>
  );
};

const ProductImageCarouselSkeletonized = withSkeleton(ProductImageCarousel, ProductImageCarouselSkeleton, [
  'product',
  'variant',
]);

export type { ProductImageCarouselProps };
export { ProductImageCarousel, ProductImageCarouselSkeletonized };
export default ProductImageCarousel;
