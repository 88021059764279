import { Discount } from 'entities/discount/discount';
import { ProductFiltersQuery } from 'entities/product/filter';

export type ProductUOMAbbr = string;

export type ProductLabTestValue = {
  value: number | [number, number];
  unitAbbr: ProductUOMAbbr;
};

export enum ProductLabTest {
  THC = 'thc',
  CBD = 'cbd',
}

export type ProductLabTests = Record<ProductLabTest, ProductLabTestValue>;

export const formatLabTestValue = (val: ProductLabTestValue) => {
  const value = Array.isArray(val.value) ? (val.value[0] === val.value[1] ? val.value[0] : val.value) : val.value;
  return `${Array.isArray(value) ? value.join(' - ') : value}${val.unitAbbr}`;
};

export const formatLabTest = (key: ProductLabTest, value: ProductLabTestValue) => ({
  key,
  name: key.toUpperCase(),
  value: formatLabTestValue(value),
  unit: value.unitAbbr,
});

export const formatLabTests = (tests?: ProductLabTests | null) => {
  if (!tests) {
    return [];
  }
  return Object.entries(tests)
    .filter(([, value]) => !!value)
    .map(([key, value]) => formatLabTest(key as ProductLabTest, value));
};

export const formatLabTestsInOneLine = (tests?: ProductLabTests | null) =>
  formatLabTests(tests).map(({ name, value }) => `${name}: ${value}`);

// размер одного юнита. количество и абревиатура единицы измерения
export type ProductUnitSize = LinkableEntity & {
  value: number;
  unitAbbr: ProductUOMAbbr;
};

// оптовые цены
export type ProductPriceTier = NamedEntity & {
  qty: number;
  price: Price$;
  promoPrice?: Price$;
};

export type StrengthLevel = NamedEntity & {
  level: number;
  maxLevel: number;
};

export enum ProductSaleType {
  MEDICAL = 'Medical',
  RECREATIONAL = 'Recreational',
  BOTH = 'Both',
}

export enum ReminderStatus {
  CanBeSet = 'CanBeSet',
  AlreadySet = 'AlreadySet',
}

// sku
export type ProductVariant = NamedEntity<VariantId> & {
  detailedLabDataExists: boolean;
  images?: Image[]; // если нету своих , то смотрим если есть в продукте
  strengthLevel?: StrengthLevel;
  labTests?: ProductLabTests;
  saleType?: ProductSaleType; // если MEDICAL, то рисуем бейдж `Medical`
  availableQty?: number; // не даем натыкать больше доступного, если приходит ноль то вместо цены выводим `Out of Stock`, не плохо бы в дизане показать
  unitSize?: ProductUnitSize;
  unitsInPackage?: number; // `${unitsInPackage} in Pack`
  price: Price$; //
  promoPrice?: Price$; // если есть промо прайс, выводим его как основной, прайс перечеркнутым
  discount?: Percent; // если есть, пихаем в бейдж
  tierPricing?: ProductPriceTier[]; // если не пустой, выводим лейбл `Tier Pricing`, учитываем при накликивание в корзину
  promos?: (Omit<Discount, 'endDate'> & { endDate?: Date })[];
  reminderStatus: ReminderStatus | null;
  isPacked: boolean;
  qtyStepSize: number;
  qtyPresets?: number[];
};

export type ProductCategory = NamedEntity & {
  imageUrl: Image | null;
  tagId: EntityID;
  filter?: ProductFiltersQuery;
};

export type ProductBrand = NamedEntity & {
  imageUrl: Image | null;
  filter?: ProductFiltersQuery;
};

export type ProductStrain = LinkableEntity & {
  // lineage?: string;
  prevalence: LinkableEntity; // пихаем в бейдж
  flavors?: LinkableEntity[];
  terpenes?: LinkableEntity[];
};

export type Flavorings = LinkableEntity[];
export type Effects = LinkableEntity[];
export type Tags = LinkableEntity[];

export type Product = NamedEntity<ProductId> & {
  // нейм продукта
  description?: string;
  category: ProductCategory; // ${category.name} by ${brand.name}
  subcategory?: LinkableEntity;
  // subcategory?: NamedEntity;
  images?: AbsoluteURL[]; // берем если нет у варианта своих

  brand?: ProductBrand; // ${category.name} by ${brand.name}
  tags?: Tags;
  effects?: Effects;
  strain?: ProductStrain; // бейдж prevalence
  flavorings?: Flavorings;
  productType?: LinkableEntity;
  qualityLine?: LinkableEntity;
  // productClass: NamedEntity;
  scents?: LinkableEntity[];

  // в снипете товара выводим первый вариант
  variants: ProductVariant[];
};

export type LaboratoryDataComponentValue = {
  name: string;
  code: string;
  min: number;
  max: number;
};
export type LaboratoryDataComponentUnit = 'MG' | '%';

export type LaboratoryDataComponent = {
  values: LaboratoryDataComponentValue[];
  unitAbbr: LaboratoryDataComponentUnit | null;
};

export type ProductLaboratoryData = {
  thc: LaboratoryDataComponent;
  cbd: LaboratoryDataComponent;
  terpenes: LaboratoryDataComponent;
};

export type TerpeneName =
  | 'A_PINENE'
  | 'BISABOLOL'
  | 'BORNEOL'
  | 'B_CARYOPHYLLENE'
  | 'B_MYRCENE'
  | 'B_PINENE'
  | 'CAMPHENE'
  | 'CAMPHOR'
  | 'CARENE'
  | 'CAROPHYLLENE_OXIDE'
  | 'CEDRENE'
  | 'CYMENE'
  | 'EUCALYPTOL'
  | 'FENCHOL'
  | 'GERANIOL'
  | 'GERANYL_ACETATE'
  | 'GUAIOL'
  | 'HUMULENE'
  | 'ISOBORNEOL'
  | 'ISOPULEGOL'
  | 'LIMONENE'
  | 'LINALOOL'
  | 'MENTHOL'
  | 'OCIMENE'
  | 'PHELLANDRENE'
  | 'PHYTOL'
  | 'PINENE'
  | 'PULEGONE'
  | 'P_CYMENE'
  | 'SABINENE'
  | 'TERPINENE'
  | 'TERPINEOL'
  | 'TERPINOLENE'
  | 'TRANS_NEROLIDOL'
  | 'VALENCENE'
  | 'Y_TERPINENE';

const getProductName = (product: Product) => {
  return product.productType?.name ? `${product.name} ${product.productType.name}` : product.name;
};

const getProductNameWithVariant = (product: Product, variant: ProductVariant) => `${product.name} ${variant.name}`;

const getProductImages = (product: Product) => {
  const productImages = product.images ?? [];

  if (productImages.length === 0 && product.variants.length === 1) {
    return product.variants[0].images ?? [];
  }

  return productImages;
};

const getImages = (product: Product, variantId?: ProductVariant['id']) => {
  if (variantId) {
    const variantImages = product.variants.find((v) => v.id === variantId)?.images ?? [];

    return variantImages.length > 0 ? variantImages : getProductImages(product);
  }

  return getProductImages(product);
};

const getFirstImage = (product: Product, variantId?: ProductVariant['id']) => {
  const [image] = getImages(product, variantId);

  return image;
};

const hasOnePromoAtLeast = (product: Product) => product.variants.some((v) => v.promos?.length !== 0);

const isOutOfStock = (variant: ProductVariant) =>
  typeof variant.availableQty !== 'undefined' ? variant.availableQty < 1 : false;

const getProductFirstVariant = (product: Product): ProductVariant => product.variants[0];
const getProductVariantById = (product: Product, variantId: ProductVariant['id']): ProductVariant | undefined =>
  product.variants.find((v) => v.id === variantId);

const getProductVariant = (product: Product, variant?: ProductVariant | ProductVariant['id']): ProductVariant =>
  (typeof variant === 'string' || typeof variant === 'number' ? getProductVariantById(product, variant) : variant) ??
  getProductFirstVariant(product);

const getProductVariantCurrentTier = ({ tierPricing = [] }: Pick<ProductVariant, 'tierPricing'>, _qtyInCart = 0) => {
  const qtyInCart = Math.max(_qtyInCart, 1);
  return tierPricing
    .slice()
    .reverse()
    .find((tier) => qtyInCart >= tier.qty);
};

const getProductVariantNextTier = ({ tierPricing = [] }: Pick<ProductVariant, 'tierPricing'>, _qtyInCart = 0) => {
  const qtyInCart = Math.max(_qtyInCart, 1);
  return tierPricing.find((item) => item.qty > qtyInCart);
};
const getProductVariantPrice = (
  { tierPricing = [], promoPrice, price }: Pick<ProductVariant, 'tierPricing' | 'promoPrice' | 'price'>,
  qtyInCart = 0,
): [price: number, oldPrice: number | undefined, basePrice: number] => {
  const basePrice = promoPrice || price;
  const tier = getProductVariantCurrentTier({ tierPricing }, qtyInCart);

  if (tier) {
    return [
      tier.promoPrice || tier.price,
      tier.promoPrice && tier.promoPrice !== tier.price ? tier.price : undefined,
      basePrice,
    ];
  }

  return [basePrice, promoPrice && promoPrice !== price ? price : undefined, basePrice];
};

const getProductVariantTierPriceRanges = (variant: Pick<ProductVariant, 'tierPricing' | 'promoPrice' | 'price'>) => {
  const [, , basePrice] = getProductVariantPrice(variant);
  const { tierPricing = [] } = variant;
  return tierPricing
    .map(({ qty, price, promoPrice }, idx, items) => {
      const finalTierPrice = promoPrice || price;
      return {
        range: idx === items.length - 1 ? [qty, Number.POSITIVE_INFINITY] : [qty, items[idx + 1].qty],
        price: finalTierPrice,
        save: basePrice - finalTierPrice,
        basePrice,
      };
    })
    .filter(({ range }) => !range.includes(1));
};

export {
  getProductName,
  isOutOfStock,
  getProductNameWithVariant,
  getImages,
  getFirstImage,
  hasOnePromoAtLeast,
  getProductVariant,
  getProductFirstVariant,
  getProductVariantById,
  getProductVariantPrice,
  getProductVariantTierPriceRanges,
  getProductVariantCurrentTier,
  getProductVariantNextTier,
};
