import { applyOffset, formatDate, updateDateTZ } from '@swe/shared/utils/date';
import { formatPhone } from '@swe/shared/utils/phone';
import { formatPrice } from '@swe/shared/utils/price';

import { MutableRefObject, useCallback, useEffect, useMemo } from 'react';

import { useStoreConfig, useStoreInfo } from 'common/providers/config';
import { useCurrentUser } from 'common/providers/user';
import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';

import { usePlatformOs } from 'common/use-cases/use-platform-os';
import { useSaleType } from 'common/use-cases/use-sale-type';
import { ReceiptProps } from 'domains/checkout/components/receipt';
import { processReceiptItems } from 'domains/checkout/use-cases/use-checkout';
import { CourierDeliveryData, useCourierLocation } from 'domains/profile/use-cases/use-courier-location';
import { useOrderCancellation } from 'domains/profile/use-cases/use-order-cancellation';
import { GetCourierNumberEndpoint } from 'endpoints/profile/orders/get-courier-number';
import { GetOrderIntervalsV2Endpoint } from 'endpoints/profile/orders/get-intervals-v2';
import GetOrderEndpoint from 'endpoints/profile/orders/get-order';
import { mapReducedProductToFullProduct, Order } from 'entities/common/orders';
import { intervalFormat } from 'entities/common/schedule';
import { Product } from 'entities/product/product';
import {
  ChatId,
  findChatById,
  getDriverChat,
  getStoreChat,
  isConfirmation,
  isDone,
  isInDelivery,
  isOrderChatVisible,
  isOutForDelivery,
  isPacking,
  isSuccessDone,
} from 'entities/profile/orders';
import { FullShopFulfillmentType, isDelivery, isPickup } from 'entities/shop/info';

const getFulfillmentTypeLabel = (type: FullShopFulfillmentType) =>
  ({
    [FullShopFulfillmentType.Delivery]: 'Delivery',
    [FullShopFulfillmentType.InStorePickup]: 'In-Store pickup',
    [FullShopFulfillmentType.CurbsidePickup]: 'Curbside pickup',
    [FullShopFulfillmentType.DriveThrough]: 'Drive Through',
    [FullShopFulfillmentType.LocalDelivery]: 'Local Delivery',
    [FullShopFulfillmentType.InStoreOrder]: 'In Store Order',
  })[type];

const prepOrderDate = (order: Order): Date | null => {
  if (order.confirmedAt) {
    return new Date(order.confirmedAt);
  }
  return null;
};

const getOrderNumber = (order?: Order) => {
  return String(order?.orderNumber || order?.id || '');
};

const prepFulfillmentInformation = (order: Order, deliveryData: CourierDeliveryData, shopOffset: number) => {
  if (!order.fulfillmentType) {
    return;
  }

  const type = getFulfillmentTypeLabel(order.fulfillmentType);
  let address = '';
  let addressPrefix = type;

  let scheduleInfo = order.fulfillmentDate ? formatDate(updateDateTZ(order.fulfillmentDate, shopOffset), 'MMMM d') : '';

  if (isDelivery(order.fulfillmentType)) {
    addressPrefix = 'Delivery to';
    address = order.location?.address ?? '';
    if (isSuccessDone(order.processStatus)) {
      addressPrefix = 'Delivered to';
    }

    switch (true) {
      case !!deliveryData.eta && (isOutForDelivery(order.processStatus) || isInDelivery(order.processStatus)):
        scheduleInfo += deliveryData.eta ? ` ETA: ${formatDate(deliveryData.eta!, 'p')}` : '';
        break;
      case order.isAsap:
        scheduleInfo += ` / ASAP`;
        break;
      default:
        scheduleInfo += order.scheduleInterval ? ` / ${intervalFormat(order.scheduleInterval)}` : '';
    }
  } else if (order.reservedUntil && Array.isArray(order.scheduleInterval) && !order.scheduleInterval.length) {
    const reservedUntil = applyOffset(order.reservedUntil, 'local');
    scheduleInfo = `RESERVED UNTIL ${formatDate(reservedUntil, 'MMMM d')} / ${formatDate(reservedUntil, 'p')}`;
  } else if (order.scheduleInterval && order.scheduleInterval.length) {
    scheduleInfo += order.scheduleInterval ? ` / ${intervalFormat(order.scheduleInterval)}` : '';
  }

  if ((order.eta || deliveryData.eta) && deliveryData.isDelayed && !isPickup(order.fulfillmentType)) {
    scheduleInfo = 'Sorry, the driver is delaying 😢 ';
  }

  if (isDone(order.processStatus)) {
    scheduleInfo = '';
  }

  return {
    status: order.processStatus,
    type,
    addressPrefix,
    address,
    scheduleInfo,
  };
};

const useOrderDetails = (id: number, skipNextLogout?: MutableRefObject<boolean>) => {
  const { saleType } = useSaleType();
  const { platformOs } = usePlatformOs();
  const {
    data: order,
    mutate: mutateOrder,
    isLoading,
    error,
  } = GetOrderEndpoint.useRequest(
    {
      saleType,
      id,
      platformOs,
    },
    {
      notifyWithSnackbar: false,
    },
  );
  const { isCourierSnapshotAvailable } = useStoreConfig();
  const courierDeliveryData = useCourierLocation(order);
  const navigate = useRouterNavigate();
  const { cancel } = useOrderCancellation();
  const hasOrderError = useMemo(() => {
    return error?.code === 'NotFound';
  }, [error?.code]);
  useEffect(() => {
    if (!isLoading && hasOrderError) {
      void navigate(Routes.Home, { replace: true });
    }
  }, [hasOrderError, isLoading, navigate]);
  const { contacts, id: storeId, location } = useStoreInfo();

  const chats = useMemo(
    () => ({
      store: getStoreChat(order?.chats ?? []),
      driver: getDriverChat(order?.chats ?? []),
    }),
    [order?.chats],
  );
  const getChatById = useCallback((id: ChatId) => findChatById(order?.chats ?? [], id), [order?.chats]);

  const toChat = useCallback(
    (chatId: string) => {
      return navigate({ pathname: Routes.ProfileOrderChat, query: { id: String(id), chatId } });
    },
    [id, navigate],
  );
  const toStoreChat = useCallback(() => {
    if (chats.store) {
      return toChat(chats.store.chatId);
    }
  }, [chats.store, toChat]);
  const toDriverChat = useCallback(() => {
    if (chats.driver) {
      return toChat(chats.driver.chatId);
    }
  }, [chats.driver, toChat]);

  const storeBlock = useMemo(() => {
    const chatProps =
      order && !order.hideForPatient && chats.store && isOrderChatVisible(order, chats.store)
        ? { messageCount: chats.store.unreadFromClient, onChat: toStoreChat }
        : {};

    const onCall =
      contacts.phone && !order?.hideForPatient
        ? () => {
            if (contacts.phone) {
              if (skipNextLogout) {
                // eslint-disable-next-line no-param-reassign
                skipNextLogout.current = true;
              }
              window.open(`tel:${formatPhone(contacts.phone, true)}`, '_self');
            }
          }
        : undefined;

    return {
      ...contacts,
      id: storeId,
      phone: contacts.phone,
      name: order?.store?.name || '',
      coords: location.coords,
      ...chatProps,
      onCall,
    };
  }, [chats.store, contacts, location.coords, order, skipNextLogout, storeId, toStoreChat]);

  const receiptItems = useMemo(() => {
    const res: ReceiptProps['items'] = (order?.items || []).map(({ name, price, images, qty }) => ({
      image: images[0],
      value: formatPrice(price),
      title: name.concat(qty > 1 ? ` (x${qty})` : ''),
      variant: 'secondary',
    }));

    return res.concat(processReceiptItems(order?.receipt?.items ?? []));
  }, [order?.items, order?.receipt?.items]);

  const headerDate = useMemo(() => {
    if (order) {
      return prepOrderDate(order);
    }
  }, [order]);

  const mapBlock = useMemo(() => {
    if (!isCourierSnapshotAvailable || !order || !order.fulfillmentType || !isInDelivery(order.processStatus)) {
      return;
    }

    const { fulfillmentType, location } = order;
    if (
      isDelivery(fulfillmentType) &&
      location &&
      storeBlock &&
      order.location &&
      order.location.coordinates &&
      courierDeliveryData.location
    ) {
      return {
        userLocation: order.location.coordinates,
        driverLocation: courierDeliveryData.location,
        storeLocation: storeBlock.coords,
        storeId: storeBlock.id,
      };
    }
  }, [isCourierSnapshotAvailable, order, storeBlock, courierDeliveryData.location]);

  const callDriver = useCallback(async () => {
    try {
      const courierNumber = await GetCourierNumberEndpoint.request({
        id,
      });
      if (courierNumber) {
        if (skipNextLogout) {
          // eslint-disable-next-line no-param-reassign
          skipNextLogout.current = true;
        }
        window.open(`tel:${formatPhone(String(courierNumber))}`, '_self');
      }
    } catch (e) {
      console.error(e);
    }
  }, [id, skipNextLogout]);
  const driverBlock = useMemo(() => {
    if (!order || !order.courier) {
      return;
    }

    const chatProps =
      chats.driver && isOrderChatVisible(order, chats.driver)
        ? { messageCount: chats.driver.unreadFromClient, onChat: toDriverChat }
        : {};

    return {
      name: order.courier.name,
      onCall: order?.fulfillmentType && order.courierOnTheWay ? callDriver : undefined,
      ...chatProps,
    };
  }, [callDriver, chats.driver, order, toDriverChat]);

  // const { requiredDocumentsNames, isDocumentsValid } = useDocumentsRequirements(order?.fulfillmentType);

  const { user, logout } = useCurrentUser();

  const orderVisitedAlertBlock = useMemo(() => {
    if (!order?.orderVisit?.comment) {
      return;
    }
    return {
      message: order?.orderVisit?.comment ?? '',
    };
  }, [order?.orderVisit?.comment]);

  const hasArriveBtn = useMemo(() => {
    return order?.fulfillmentType === FullShopFulfillmentType.CurbsidePickup && !order?.orderVisit?.comment.length;
  }, [order?.fulfillmentType, order?.orderVisit?.comment.length]);

  const { data: intervals = [], isLoading: isLoadingInterval } = GetOrderIntervalsV2Endpoint.useRequest(
    !hasOrderError && order?.fulfillmentType && isPickup(order.fulfillmentType) && !hasOrderError
      ? { orderId: id }
      : null,
    {
      notifyWithSnackbar: false,
    },
  );

  const hasPickupTimeWindow = useMemo(
    () => !!(order?.fulfillmentType && isPickup(order.fulfillmentType) && !isLoadingInterval && intervals.length),
    [intervals, isLoadingInterval, order?.fulfillmentType],
  );

  const { timezoneShiftInHrs } = useStoreConfig();

  const fulfillmentInformationBlock = useMemo(() => {
    if (!order) {
      return;
    }
    return prepFulfillmentInformation(order, courierDeliveryData, timezoneShiftInHrs);
  }, [courierDeliveryData, order, timezoneShiftInHrs]);

  const documentsAlertBlock = useMemo(() => {
    return undefined;
    // if (isDocumentsValid) {
    //   return;
    // }
    //
    // return {
    //   documentsRequiredNames: requiredDocumentsNames.join(', '),
    // };
  }, []);

  const hintHowToModifyBlock = useMemo(() => {
    return order && (isPacking(order?.processStatus) || isConfirmation(order?.processStatus));
  }, [order]);

  const orderedItems = useMemo(() => {
    return order?.items?.map((item) => {
      const productVariant = item.catalogProduct?.variants.find((v) => String(v.id) === String(item.variantId));

      return productVariant
        ? ({
            ...item.catalogProduct,
            qty: item.qty,
            variants: [
              {
                ...productVariant,
                // TODO: Dirty hack, need to be fixed properly by typing product entity
                price: item.price,
                promoPrice: undefined,
                promos: [],
                tierPricing: undefined,
                availableQty: 1,
                labTests: undefined,
              },
            ],
          } as Product)
        : mapReducedProductToFullProduct(item);
    });
  }, [order?.items]);

  const cancelOrder = useCallback(async () => {
    await cancel(order?.id);
    await mutateOrder();
  }, [cancel, mutateOrder, order?.id]);

  return {
    order,
    contacts,
    storeBlock,
    receiptItems,
    headerDate,
    mapBlock,
    driverBlock,
    driverLocation: courierDeliveryData,
    documentsAlertBlock,
    userEmail: user?.email,
    hasArriveBtn,
    hasPickupTimeWindow,
    mutateOrder,
    orderVisitedAlertBlock,
    fulfillmentInformationBlock,
    toChat,
    toStoreChat,
    toDriverChat,
    getChatById,
    hintHowToModifyBlock,
    orderedItems,
    cancelOrder,
    isLoading,
    user,
    logout,
  };
};

export { useOrderDetails, prepOrderDate, prepFulfillmentInformation, getOrderNumber };
export default useOrderDetails;
