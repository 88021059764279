import { useIsAndroid, useIsIOS } from '@swe/shared/tools/media';

import { parseUA } from 'app/utils';

import { useIsPwa } from 'common/providers/pwa/use-is-pwa';

type PlatformOs = 'web' | 'webIos' | 'webAndroid' | 'pwaIos' | 'pwaAndroid' | 'pwa';

const buildPlatformOs = (isInStandaloneMode: boolean, isIOS: boolean, isAndroid: boolean) => {
  const os = isIOS ? 'Ios' : isAndroid ? 'Android' : '';
  return [isInStandaloneMode ? 'pwa' : 'web', os].filter(Boolean).join('') as PlatformOs;
};

const usePlatformOs = () => ({
  platformOs: buildPlatformOs(useIsPwa(), useIsIOS(), useIsAndroid()),
});

const getPlatformOs = ({ userAgent }: { userAgent: string }) => {
  const { isIOS, isAndroid } = parseUA(userAgent);
  return buildPlatformOs(false, isIOS, isAndroid);
};

export { usePlatformOs, getPlatformOs };

export type { PlatformOs };
