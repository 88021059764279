import { reactErrorHandler, captureException } from '@sentry/react';
import { useMounted } from '@swe/shared/hooks/use-mounted';
import SharedProvider from '@swe/shared/provider';
import { GoogleApiProvider } from '@swe/shared/providers/google-api';
import { HeadProviderProps, useHead } from '@swe/shared/providers/head';
import { PersistStateStorageProvider } from '@swe/shared/providers/persist-state';
import SnackbarProvider from '@swe/shared/providers/snackbar';
import { IntlProvider } from '@swe/shared/tools/intl';
import { Language } from '@swe/shared/tools/intl/core/i18n';
import { Error500 } from '@swe/shared/ui-kit/components/error-500';
import { ErrorBoundary } from '@swe/shared/ui-kit/components/error-boundary';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

import { isSSR } from '@swe/shared/utils/environment';

import posthog from 'posthog-js';

import { PostHogProvider } from 'posthog-js/react';

import { firebase } from 'app/firebase';
import { useClientWidth } from 'app/hooks';
import { getThemeUtils } from 'app/theme';
import { AppSettings, PageLayoutProps } from 'app/types';
import { parseUA } from 'app/utils';

import { ActionRequiredNotifier } from 'common/containers/action-required-notifier';
import AgeRestriction from 'common/containers/age-restriction';
import CommonMeta from 'common/containers/common-meta';
import Footer from 'common/containers/footer';
import Header from 'common/containers/header';
import SubHeader from 'common/containers/header/containers/sub-header';
import SupHeader from 'common/containers/header/containers/sup-header';
import { MedicalCardAssistance } from 'common/containers/medical-card-assistance';
import { ProductDetailsModal } from 'common/containers/product-details-modal';
import { SignUpNotifier } from 'common/containers/sign-up-notifier';
import { SignupIncentivization } from 'common/containers/signup-incentivization';
import { MainLayout } from 'common/layouts/main';
import AnalyticsProvider from 'common/providers/analytics';
import { AnalyticsAdaptersConfig } from 'common/providers/analytics/adapters';
import { CartProvider } from 'common/providers/cart';
import StoreConfigProvider from 'common/providers/config';
import { GuestProvider } from 'common/providers/guest';
import { ChatMessageNotifier } from 'common/providers/message';
import PickupDeliveryProvider from 'common/providers/pickup-delivery';
import { PWAProvider } from 'common/providers/pwa';
import { useInitStorages } from 'common/providers/storages';
import UserProvider from 'common/providers/user';
import { PDPRouterProvider } from 'common/use-cases/use-pdp';

import { SignUpProvider } from 'domains/authorization/containers/sign-up/use-cases/use-sign-up';
import { ReferralProgramSubscriber } from 'domains/profile/containers/referral-program/containers/referral-program-subscriber';

import '@swe/shared/ui-kit/theme/provider/global.css';

const onError = reactErrorHandler((error, errorInfo) => {
  // eslint-disable-next-line no-console
  console.error('[React Uncaught error]', error, errorInfo.componentStack);
});

const ErrorFallback = ({ error }: { error: any }) => <Error500 error={error} />;

const PageHead = ({ themeBuild }: { themeBuild: AppSettings['themeBuild'] }) => {
  const Head = useHead();

  return (
    <Head>
      {themeBuild.assetPrefix && <script>{`window.__sw_assetPrefix = '${themeBuild.assetPrefix}';`}</script>}
      {themeBuild.hasGoogleFonts && (
        <>
          <link
            rel="preconnect"
            href="https://fonts.googleapis.com"
          />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="anonymous"
          />
        </>
      )}
      {themeBuild.fonts.map((font) => (
        <link
          key={font.src}
          rel="preload"
          as="font"
          href={font.src}
          type={font.type}
          crossOrigin="anonymous"
        />
      ))}
      {themeBuild.assetPrefix && (
        <link
          rel="preconnect"
          href={themeBuild.assetPrefix}
        />
      )}
      <link
        rel="preconnect"
        href="https://sweedpos.s3.amazonaws.com"
      />
      <script
        type="module"
        defer
      >{`
      import T from '${themeBuild.jsUrl}';
      if (!window.JS_TOKENS) {
        window.JS_TOKENS = {};
      }
      Object.assign(window.JS_TOKENS, T);
      `}</script>
      {themeBuild.cssFonts && <style data-sw-fonts="true">{themeBuild.cssFonts}</style>}
      <link
        rel="stylesheet"
        type="text/css"
        href={themeBuild.cssUrl}
      />
    </Head>
  );
};

type AppLayoutProps = ComponentHasChildren &
  AppSettings & {
    layoutProps?: PageLayoutProps;
    Head: HeadProviderProps['head'];
    analyticsConfig?: AnalyticsAdaptersConfig;
  };

const AppLayout = ({
  children,
  storagesFallback,
  reqUserAgent,
  clientWidthFallback,
  themeBuild,
  error,
  translations,
  endpointsFallback,
  storeId,
  reqHasAuthCookie,
  layoutProps,
  Head,
  analyticsConfig,
}: AppLayoutProps) => {
  const revalidateOnInit = reqHasAuthCookie ?? true;
  const themeUtils = getThemeUtils(themeBuild);
  const storages = useInitStorages(storagesFallback);
  const { isOpera, isIOS, isAndroid } = parseUA(isSSR ? reqUserAgent : window.navigator.userAgent);
  const clientWidth = useClientWidth(clientWidthFallback);

  useMounted(() => {
    void firebase.init();
  });

  try {
    if (error) {
      throw error;
    }

    return (
      <PostHogProvider client={posthog}>
        <IntlProvider
          language={Language.En}
          messages={translations}
        >
          <SharedProvider
            {...themeUtils}
            errorFallback={ErrorFallback}
            onError={onError}
            cacheFallback={endpointsFallback}
            revalidateOnInit={revalidateOnInit}
            clientWidth={clientWidth}
            isIOS={isIOS}
            isAndroid={isAndroid}
            head={Head}
          >
            <StoreConfigProvider storeId={storeId}>
              {({ googleMapsApiKey }) => (
                <GoogleApiProvider apiKey={googleMapsApiKey}>
                  <PageHead themeBuild={themeBuild} />
                  <PersistStateStorageProvider storages={storages}>
                    <SnackbarProvider>
                      <PDPRouterProvider>
                        <CommonMeta />
                        <GuestProvider>
                          <UserProvider hasAuthCookie={reqHasAuthCookie}>
                            <AnalyticsProvider config={analyticsConfig}>
                              <PWAProvider isOpera={isOpera}>
                                <CartProvider>
                                  <SignUpProvider>
                                    <PickupDeliveryProvider>
                                      <ProductDetailsModal />
                                      <ReferralProgramSubscriber />
                                      <ChatMessageNotifier />
                                      <MainLayout
                                        preHeader={
                                          <>
                                            <SignUpNotifier />
                                            <ActionRequiredNotifier />
                                            <SupHeader />
                                          </>
                                        }
                                        header={<Header />}
                                        subheader={<SubHeader />}
                                        footer={<Footer />}
                                        preFooter={<MedicalCardAssistance />}
                                        {...layoutProps}
                                      >
                                        <ErrorBoundary>{children}</ErrorBoundary>
                                      </MainLayout>
                                      <AgeRestriction />
                                      <SignupIncentivization />
                                    </PickupDeliveryProvider>
                                  </SignUpProvider>
                                </CartProvider>
                              </PWAProvider>
                            </AnalyticsProvider>
                          </UserProvider>
                        </GuestProvider>
                      </PDPRouterProvider>
                    </SnackbarProvider>
                  </PersistStateStorageProvider>
                </GoogleApiProvider>
              )}
            </StoreConfigProvider>
          </SharedProvider>
        </IntlProvider>
      </PostHogProvider>
    );
  } catch (e) {
    captureException(e);
    return (
      <PostHogProvider client={posthog}>
        <SharedProvider
          {...themeUtils}
          errorFallback={ErrorFallback}
          onError={onError}
          cacheFallback={endpointsFallback}
          clientWidth={clientWidth}
          isIOS={isIOS}
          isAndroid={isAndroid}
          head={Head}
        >
          <PageHead themeBuild={themeBuild} />
          <Error500 error={e as Error} />
        </SharedProvider>
      </PostHogProvider>
    );
  }
};

export default AppLayout;
