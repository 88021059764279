import { ApiErrorCode } from '@swe/shared/network/endpoint-factories/modern/types';

import { useCallback, useEffect, useState } from 'react';

import { usePDP, usePDPControls } from 'common/use-cases/use-pdp';
import { usePlatformOs } from 'common/use-cases/use-platform-os';
import { useSaleType } from 'common/use-cases/use-sale-type';
import GetProductEndpoint from 'endpoints/product/get-product';
import GetProductByVariantIdEndpoint from 'endpoints/product/get-product-by-variant-id';
import { Product, ProductVariant } from 'entities/product/product';

const getVariant = (product: Product, variantId?: ProductVariant['id']) =>
  variantId ? product.variants.find((v) => String(v.id) === String(variantId)) : product.variants[0];

// TODO: Some kind of trash. Need refactoring.
const useProductMeta = (product?: Product, variant?: ProductVariant['id']) => {
  const { isPDPPage } = usePDP();
  const { open } = usePDPControls();

  const [innerVariant, setInnerVariant] = useState<ProductVariant['id']>();

  const setVariant = useCallback(
    async (v: ProductVariant) => {
      if (product) {
        // open(product, v, isPDPPage ? 'link' : undefined);
        open({ product, variant: v, strategy: isPDPPage ? 'link' : undefined });
      }
      setInnerVariant(v.id);
    },
    [open, product, isPDPPage],
  );

  const selectedVariant = product ? getVariant(product, innerVariant ?? variant) : undefined;

  useEffect(() => {
    setInnerVariant(null!);
  }, [product]);

  return {
    product,
    variant: selectedVariant,
    setVariant,
  };
};

const useProductById = (id?: Product['id'], variant?: ProductVariant['id']) => {
  const { saleType } = useSaleType();
  const { platformOs } = usePlatformOs();
  const {
    data: product,
    error,
    isLoading,
    isValidating,
  } = GetProductEndpoint.useRequest(id ? { productId: id, saleType, platformOs } : null);

  const meta = useProductMeta(product, variant);

  return {
    ...meta,
    isLoading,
    isValidating,
    error,
  };
};

const useProductByVariant = (variantId?: ProductVariant['id']) => {
  const { saleType } = useSaleType();
  const { platformOs } = usePlatformOs();
  const {
    data: product,
    error,
    isLoading,
    isValidating,
    mutate,
  } = GetProductByVariantIdEndpoint.useRequest(
    variantId ? { variantId, saleType, platformOs } : null,
    { notifyWithSnackbar: false },
    { keepPreviousData: false },
  );
  const isProductNotFound = error?.code === ApiErrorCode.NotFound;

  const meta = useProductMeta(product, variantId);

  return {
    ...meta,
    isProductNotFound,
    isLoading,
    isValidating,
    error,
    mutate,
  };
};

export { useProductById, useProductByVariant };
export default useProductById;
